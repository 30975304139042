<template>
  <div class="app-container flex-form">
    <transition
      name="fade-transform"
      mode="out-in"
    >
      <el-form
        v-if="!changePassword"
        key="1"
        v-loading="loading"
        label-width="170px"
      >
        <el-form-item>
          <h2>
            My Profile
          </h2>
        </el-form-item>

        <el-form-item label="Username">
          <div class="info-field text-blue">
            {{ user.username }}
          </div>
        </el-form-item>

        <el-form-item label="Role">
          <div class="info-field">
            <span :class="user.role">{{ user.role }}</span>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button
            class="filter-item align-right"
            type="primary"
            :loading="loading"
            @click="changePassword = true"
          >
            Change Password
          </el-button>
        </el-form-item>
      </el-form>

      <el-form
        v-if="changePassword"
        key="2"
        ref="changePasswordForm"
        v-loading="loading"
        :model="changePasswordForm"
        label-width="150px"
        :rules="rules"
        autocomplete="off"
      >
        <el-form-item>
          <h2>
            My Profile
          </h2>
        </el-form-item>

        <el-form-item
          label="New Password"
          prop="password"
        >
          <el-input
            v-model="changePasswordForm.password"
            placeholder="At least 6 digit long"
            show-password
          />
          <password
            v-model="changePasswordForm.password"
            placeholder="At least 6 digit long"
            :strength-meter-only="true"
            @score="setScore"
          />
        </el-form-item>

        <el-form-item class="flex-form__action-buttons">
          <el-button
            type="primary"
            :loading="loading"
            :disabled="isSubmitDisabled"
            @click="onSubmit"
          >
            Save
          </el-button>

          <el-button @click="changePassword = false">
            Cancel
          </el-button>
        </el-form-item>
      </el-form>
    </transition>
  </div>
</template>

<script>
import { getInfo, updateSettings } from '@/api/account';
import Password from 'vue-password-strength-meter';

export default {
  name: 'MyProfile',
  components: { Password },
  data() {
    return {
      loading: false,
      changePassword: false,
      user: {},
      changePasswordForm: {
        email: '',
        password: '',
      },
      score: 0,
      rules: {
        password: [
          {
            required: true,
            trigger: 'blur',
            message: 'Password is required',
          },
          {
            min: 6,
            trigger: 'blur',
          },
          {
            validator: (rule, value, callback) => {
              if (this.score < 3) {
                callback(new Error('Password not strong enough'));
              }
            },
            trigger: 'blur',
            message: 'Password not strong enough',
          },
        ],
      },
    };
  },
  computed: {
    isSubmitDisabled() {
      return this.score < 2 || this.changePasswordForm.password.length === 0;
    },
  },
  created() {
    this.getInfo();
  },
  activated() {
    this.getInfo();
  },
  methods: {
    async getInfo() {
      try {
        this.loading = true;
        this.user = {};
        const data = await getInfo();
        this.user = data.user;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.$router.push({
          name: 'Dashboard',
        });
      }
    },
    setScore(score) {
      this.score = score;
    },
    onSubmit() {
      this.changePasswordForm.email = this.user.email;
      this.$refs.changePasswordForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            await updateSettings(this.changePasswordForm);

            this.resetForm();
            this.loading = false;
            this.changePassword = false;

            this.$notify({
              title: 'Success',
              message: 'Password has been changed successfully',
              type: 'success',
              duration: 3000,
            });
          } catch (e) {
            this.resetForm();

            this.loading = false;
          }
        }
      });
    },
    resetForm() {
      this.changePasswordForm.password = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 25px;
}

.inline {
  width: 65%;
  margin-right: 12px;
}

@media only screen and (max-width: 768px) {
  .inline {
    margin-bottom: 10px;
    width: 100%;
  }
}
</style>
